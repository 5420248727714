<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Complete the given flow chart illustrating the steps in the <i>Extraction</i> lab -
        <i>Parts 1 and 2.</i>
      </p>
      <p class="mb-2">
        <b>Step 1.</b> <i>Separation of the neutral compound and the unknown acid.</i>
      </p>

      <p>
        <v-select
          v-model="inputs.input1"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        10 mL
        <v-select
          v-model="inputs.input2"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <!--image-->
      <p>(image placeholder)</p>
      swirl ->
      <!--image-->
      <p>(image placeholder)</p>
      <p>
        +
        <v-select
          v-model="inputs.input3"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        ->
      </p>

      <p>
        Shake and vent the funnel every 30 seconds until no further
        <v-select
          v-model="inputs.input4"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        gas build-up is observed. Allow the layers to separate. Drain layer into a clean flask.
      </p>
      <p>
        -> Wash the top layer with
        <v-select
          v-model="inputs.input5"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p>
        . Drain the resulting
        <v-select
          v-model="inputs.input6"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        layer into the same flask.
      </p>
      <p>-> Label the flask and set it aside.</p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LB_A1Q16a',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options: [
        {text: 'melting point', value: 'meltingPoint'},
        {text: 'bottom', value: 'bottom'},
        {text: '0.20 g unknown', value: '020unknown'},
        {text: 'MeOH', value: 'MeOH'},
        {text: '3 mL deionized (DI) water', value: 'deionizedWater'},
        {text: '-7 mL 1.5 M NaOH', value: 'NaOH'},
        {text: 'drying agent', value: 'dryingAgent'},
        {text: 'hood', value: 'hood'},
        {text: '5 min.', value: '5min'},
        {text: 'tared', value: 'tared'},
        {text: 'top layer', value: 'topLayer'},
        {text: 'diethyl ether', value: 'diethylEther'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
